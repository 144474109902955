import { useEffect, useState } from "react"

export function useIsVisible(
  ref?: React.MutableRefObject<HTMLElement | null | undefined>,
) {
  const [isIntersecting, setIntersecting] = useState(false)

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIntersecting(Boolean(entry?.isIntersecting))
    })

    if (ref?.current) {
      observer.observe(ref.current)
    }

    return () => {
      observer.disconnect()
    }
  }, [ref])

  return isIntersecting
}
