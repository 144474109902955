import type { MetaFunction } from "react-router"
import { Features } from "./features"

let appName = "Visback"

export const meta: MetaFunction = () => {
  return [
    { title: appName },
    {
      name: "description",
      content: "Visual feedback for your designs.",
    },
  ]
}

export default function Home() {
  return (
    <div className="min-h-screen text-foreground bg-muted">
      <main className="container mx-auto max-w-(--breakpoint-lg) mb-10 px-4 grid gap-12">
        <Hero />
        <Features />
        <CallToActionSection />
      </main>
    </div>
  )
}

function Hero() {
  return (
    <section className="">
      <div className="pt-20">
        <h1 className="text-5xl font-bold mb-4">
          Visual feedback for your designs.
        </h1>
        <p className="text-xl text-muted-foreground mb-8 text-balance">
          Automatic design reviews, tailored to your style.
        </p>
      </div>
    </section>
  )
}

function CallToActionSection() {
  return (
    <section className="py-8">
      <div className="max-w-xl">
        <h1 className="text-4xl font-bold mb-4">Rethink the feedback cycle.</h1>
        <p className="text-xl text-muted-foreground mb-8">
          Give your team the power to iterate quickly with <b>{appName}</b>,{" "}
          <i>reduce design review times</i> — so you can focus on creating, not
          revisions.
        </p>

        {/* <p className="text-muted-foreground mb-4">
			Coming soon. Follow us on{" "}
			<a href="https://bsky.app" target="_blank" rel="noopener noreferrer" className="underline">
				bsky.app
			</a>{" "}
			for updates.
		</p> */}
      </div>
    </section>
  )
}
